import React, { Component } from "react";

class LangSwitcher extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isToggleOn: true };

		// This binding is necessary to make `this` work in the callback
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		this.setState(prevState => ({
			isToggleOn: !prevState.isToggleOn
		}));
	}

	render() {
		return (
			<a onClick={this.handleClick}>
				{this.state.isToggleOn ? "English" : "Srpski"}
			</a>
		);
	}
}

export default LangSwitcher;
