import React, { Component } from "react";

import styled from "styled-components";
import { Card, Header, Layout } from "../components";
import config from "../../config/site";
import LangSwitch from "../components/LangSwitch";
import theme from "../../config/theme";

const BG = styled.div`
	background-color: ${props => props.theme.colors.bg};
`;

const Content = styled.div`
	margin: 0rem auto 0 auto;
	max-width: 50%;
	padding: 0 ${props => props.theme.contentPadding} 6rem;
	position: relative;
	h1 {
		font-size: 3em;
		margin-top: 0;
	}
	@media (max-width: ${theme.breakpoints.l}),
		(max-device-width: ${theme.breakpoints.l}) {
		max-width: 85%;
		h1 {
			font-size: 2em;
			margin-top: 0;
		}
	}
	@media (max-width: ${theme.breakpoints.m}),
		(max-device-width: ${theme.breakpoints.m}) {
		max-width: 100%;
	}
	@media (max-width: ${theme.breakpoints.s}),
		(max-device-width: ${theme.breakpoints.s}) {
		max-width: 100%;
	}
`;

const SwitchPosition = styled.div`
	margin: -7rem auto 0 auto;
	max-width: 50%;
	padding: 0;
	position: relative;
	text-align: right;
	a {
		cursor: pointer;
	}
	@media (max-width: ${theme.breakpoints.l}),
		(max-device-width: ${theme.breakpoints.l}) {
		max-width: 85%;
	}
	@media (max-width: ${theme.breakpoints.m}),
		(max-device-width: ${theme.breakpoints.m}) {
		max-width: 85%;
	}
	@media (max-width: ${theme.breakpoints.s}),
		(max-device-width: ${theme.breakpoints.s}) {
		max-width: 85%;
	}
`;

export default class Exhibition extends Component {
	constructor(props) {
		super(props);
		this.state = { show: true };

		this.toggleDiv = this.toggleDiv.bind(this);
	}

	toggleDiv = () => {
		const { show } = this.state;
		this.setState({ show: !show });
	};

	render() {
		return (
			<Layout>
				<Header
					avatar={config.avatar}
					name={config.name}
					location={config.location}
					socialMedia={config.socialMedia}
				/>
				<SwitchPosition>
					<div onClick={this.toggleDiv}>
						<LangSwitch />
					</div>
				</SwitchPosition>
				<BG>
					<Content>
						{this.state.show && <Box />}
						{!this.state.show && <Box2 />}
					</Content>
				</BG>
			</Layout>
		);
	}
}

class Box extends Component {
	render() {
		return (
			<div>
				<h1>BIOGRAFIJA</h1>
				<p>
                DraganKurucić, rođen 1962. uNovomSadu, od 1990-te se profesionalno bavi fotografijom.Diplomirao nafakultetu zaprimenjenu fotografijugde je i specijalizirao iz oblasti foto-ilustracije.Diplomu akademskog umetnika stiče na akademiji umetnostu u NovomSadu gde završava i master studije fotografije.
				</p>
				<p>
                Pored umetničke fotografije radi i foto-dizajn (industrijsku-primenjenufotografiju) u ateljeu na Petrovaradinskoj tvrđavi. Veliki uspeh postiže u foto-monografijama. Dobitnik je vise nagrada iz oblasti foto-dizajna (kalendari ,ambalažeiplakat)
				</p>
				<p>
					Član je udruženja primenjenih umetnika i dizajnera Vojvodine
					&ndash; UPIDIV, udruženja umetnika sa Petrovaradinske
					tvrđave &laquo;Likovni krug&raquo; i Evropskog instituta za
					fotografiju. Objavljivao je fotografije u časopisima:
					Rumunije, Mađarske, Francuske, Italije i Japana.
				</p>
				<p>
					Pored mnogobrojnih grupnih izložbi značajnije samostalne
					izložbe su:
					<br /> Novi Sad, 1995 - Muzej Vojvodine <br />
					Pariz, 1996 - Centre Culturel Yougoslave- Jugoslovenski
					kulturni centar
					<br />
					London, 1998. - European Academy - Evropska akademija
					umetnosti <br />
					Pariz, 1998. - FIFA World Cup - Svetsko prvenstvo u fudbalu{" "}
					<br />
					London, 1998. - Grinic gallery Stokholm, 1998. - Evropski
					dani kulture <br />
					Prag, 2000. - Catholic church
					<br />
					Budimpe&scaron;ta, 2002. - City gallery&nbsp;&nbsp; <br />
					Novi Sad, 2016. - Muzej Vojvodine
				</p>
			</div>
		);
	}
}

class Box2 extends Component {
	render() {
		return (
			<div>
				<h1>BIOGRAPHY</h1>
				<p>
                Dragan Kurucić, born in 1962 in Novi Sad has been a professional photographer since 1990. He graduated from the Faculty of Applied Photography, where he specialized in photographic illustration. He received his BFA at the Academy of Arts in Novi Sad, where he also completes a Master’s Degree in Photography.
				</p>
				<p>
                In addition to art photography, he also works on projects in the area of photographic design (industrial applied photography) at the studio in Petrovaradin fortress. He achieved great success in monograph photography and received numerous awards in the area of photographic design (calendars, containers, and posters).
				</p>
				<p>
                He is a member of the Association of Applied Artists and Designers of Vojvodina (UPIDIV), Association of Artists from Petrovaradin Fortress “Art Circle,” as well as European Institute for Photography. He published photographs in journals in Romania, Hungary, France, Italy, and Japan.
				</p>
				<p>
					In addition to numerous group exhibitions, his most
					significant individual exhibits include:
					<br />
					Novi Sad, 1995 - Museum of Vojvodina <br />
					Paris, 1996 &ndash; Centre Culturel Yougoslave - Yugoslav
					Cultural Center <br />
					London, 1998 &ndash; European Academy
					<br />
					Paris, 1998 &ndash; FIFA World Cup <br />
					London, 1998 &ndash; The Greenwich Gallery Stockholm, 1998
					&ndash; European Days of Culture <br />
					Prague, 2000 - Catholic church
					<br />
					Budapest, 2002 - City Gallery&nbsp;&nbsp; <br />
					Novi Sad, 2016 - Museum of Vojvodina
				</p>
			</div>
		);
	}
}